import Log from '../services/Log'

export const copyToClipboard = (value: string) => {
  try {
    navigator.clipboard.writeText(value)
  } catch (e) {
    if (e instanceof Error) {
      Log.error('Unable to copy to clipboard: ' + e.message, window.location.href)
    } else {
      Log.error('Unable to copy to clipboard, unknown error', window.location.href)
    }
  }
}
