import React, { useState } from 'react'
import { StyledPromoCodeTextField, DiscountMessageTypography } from './NewsLetterDrawerContent.style'
import { copyToClipboard } from '../../../../utils/clipboard'
import { Tooltip } from '@mui/material'
import { PopperWithStyle } from '../../../../components/UI/Tooltip/Tooltip.style'
import { TFunction } from 'i18next'
import { IPromoCodeField } from '@foundation/hooks/useNewsletterSubscriptionSuccess'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'

interface PromoCodeWithTooltipProps {
  translate: TFunction
  promoCodeFields: IPromoCodeField[]
}
export function PromoCodeWithTooltip(props: PromoCodeWithTooltipProps) {
  const { translate, promoCodeFields } = props

  const [activeTooltipCode, setActiveTooltipCode] = useState<string>('')
  const onPromoCodeCopy = (promoCode: string, setShowToolTip?: (boolean) => void) => {
    copyToClipboard(promoCode)
    if (setShowToolTip) {
      setShowToolTip(promoCode)
      setTimeout(() => {
        setShowToolTip('')
      }, 1000)
    }
  }

  return (
    <>
      {promoCodeFields.map((promoCodeField, index) => {
        const { code, description } = promoCodeField
        return (
          <div key={`newsletter_success_promocode_${index}`}>
            <DiscountMessageTypography dangerouslySetInnerHTML={{ __html: description }} />
            <Tooltip
              PopperComponent={props => <PopperWithStyle colorVariant={'darkgray'} {...props} />}
              open={code === activeTooltipCode}
              title={translate('NewsLetterDrawerContent.CopiedCode') || 'Copied code'}
              arrow
              placement="top"
            >
              <div>
                <StyledPromoCodeTextField onClick={() => onPromoCodeCopy(code, setActiveTooltipCode)}>
                  {code}
                  <SVGIcon library="account" name="copy" size={24} />
                </StyledPromoCodeTextField>
              </div>
            </Tooltip>
          </div>
        )
      })}
    </>
  )
}
